var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": _vm.$t("cip.dc.DTManagement.title.NewDetectionTask"),
        },
        on: {
          "head-cancel": _vm.cancelFn,
          "head-save": function ($event) {
            return _vm.saveFn("save")
          },
          "head-saveback": function ($event) {
            return _vm.saveFn("back")
          },
        },
      }),
      _c(
        "div",
        { staticStyle: { background: "#fff", padding: "20px" } },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.addform,
                disabled: _vm.disabled,
                "label-position": "right",
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("cip.dc.QTemplate.field.inputData"),
                            prop: "taskName",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.addform.taskName,
                              callback: function ($$v) {
                                _vm.$set(_vm.addform, "taskName", $$v)
                              },
                              expression: "addform.taskName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticStyle: { width: "100%", height: "20px" } }),
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _vm._v(_vm._s(_vm.$t("AiSuanfa.detail.placeholder"))),
              ]),
              _c("div", { staticStyle: { width: "100%", height: "20px" } }),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("cip.dc.QTemplate.field.DataTheme"),
                            prop: "sourceId2",
                          },
                        },
                        [
                          _c("el-cascader", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "show-all-levels": false,
                              options: _vm.sourceList,
                              props: {
                                value: "id",
                                label: "name",
                                children: "children",
                                expandTrigger: "hover",
                              },
                            },
                            on: { change: _vm.sourceChange },
                            model: {
                              value: _vm.addform.sourceId2,
                              callback: function ($$v) {
                                _vm.$set(_vm.addform, "sourceId2", $$v)
                              },
                              expression: "addform.sourceId2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("cip.dc.QTemplate.field.DataObject"),
                            prop: "dataObjectId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              on: { change: _vm.sourceChange2 },
                              model: {
                                value: _vm.addform.dataObjectId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addform, "dataObjectId", $$v)
                                },
                                expression: "addform.dataObjectId",
                              },
                            },
                            _vm._l(_vm.sourceList2, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.code, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "cip.dc.QTemplate.field.LimitTheNumberOfEntries"
                            ),
                            prop: "maxLimit",
                          },
                        },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              controls: false,
                              min: 0,
                              label: _vm.$t(
                                "cip.dc.QTemplate.field.LimitTheNumberOfEntries"
                              ),
                            },
                            model: {
                              value: _vm.addform.maxLimit,
                              callback: function ($$v) {
                                _vm.$set(_vm.addform, "maxLimit", _vm._n($$v))
                              },
                              expression: "addform.maxLimit",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("cip.dc.CJudgmentTask.field.remarks"),
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            model: {
                              value: _vm.addform.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.addform, "remark", $$v)
                              },
                              expression: "addform.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.$route.query.algorithm_id != 8 &&
                        _vm.$route.query.algorithm_id != 41,
                      expression:
                        "\n          $route.query.algorithm_id != 8 && $route.query.algorithm_id != 41\n        ",
                    },
                  ],
                  attrs: { span: 12 },
                },
                [
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.$t("cip.dc.QTemplate.field.CompareFields"))
                    ),
                  ]),
                  _c(
                    "el-table",
                    {
                      ref: "y1table",
                      staticStyle: { width: "100%" },
                      attrs: {
                        border: "",
                        data: _vm.columnList,
                        "header-cell-class-name": _vm.leftheaderStyle,
                      },
                      on: {
                        "selection-change": _vm.handleSelectionChangey1,
                        select: _vm.selecty1,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "55" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "fieldComment",
                          label: _vm.$t("AiSuanfa.detail.zh"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "fieldName",
                          label: _vm.$t("cip.dc.QTemplate.field.field"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "dataType",
                          label: _vm.$t("cip.dc.QTemplate.field.dataType"),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("cip.dc.QTemplate.field.sortField"))),
                  ]),
                  _c(
                    "el-table",
                    {
                      ref: "x1table",
                      staticStyle: { width: "100%" },
                      attrs: {
                        border: "",
                        data: _vm.columnList,
                        "header-cell-class-name": _vm.leftheaderStyle,
                      },
                      on: {
                        "selection-change": _vm.handleSelectionChange,
                        select: _vm.selectx1,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "55" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "fieldComment",
                          label: _vm.$t("AiSuanfa.detail.zh"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "fieldName",
                          label: _vm.$t("cip.dc.QTemplate.field.field"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "dataType",
                          label: _vm.$t("cip.dc.QTemplate.field.dataType"),
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "dataType",
                          label: _vm.$t("cip.dc.QTemplate.field.sort"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "tableSclect",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "cip.dc.QTemplate.field.sort"
                                      ),
                                    },
                                    model: {
                                      value: scope.row.sortRule,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "sortRule", $$v)
                                      },
                                      expression: "scope.row.sortRule",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        label: _vm.$t(
                                          "cip.dc.dataobjDComission.upsort"
                                        ),
                                        value: "0",
                                      },
                                    }),
                                    _c("el-option", {
                                      attrs: {
                                        label: _vm.$t(
                                          "cip.dc.dataobjDComission.downsort"
                                        ),
                                        value: "1",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }